@charset 'UTF-8';

:where(button.btn, a.btn):not(:empty) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.modal-body {
    max-height: none;
}

body.modal-open {
    overflow: hidden;
}
